@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
}

body {
  @apply bg-gray-200;
}

a {
  @apply text-sm text-gray-700 no-underline;
}

a:hover {
  @apply text-gray-900;
}

.bgLogin {
  background-color: #505880;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

.status-header {
  position: relative;
}
.status-monto {
  position: absolute;
  right: 0px;
  bottom: -20px;
  font-size: 18px;
  font-weight: bolder;
}
.status-fecha {
  position: absolute;
  top: -10px;
  right: 4px;
  font-size: 12px;
}
.status-fecha-lista {
  font-size: 12px;
}
.status-user-update {
  font-size: 12px;
}

.status-estado {
  font-size: 14px;
}

.status-comment {
  font-size: 13px;
}

.tab-status {
  padding: 0px 10px;
}

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}
